import React, { useMemo } from "react";
import {
  BetCard,
  BetMessage,
  BetPick,
  HUDInstructions,
  HUDPanelEmpty,
  HUDPanelNotification,
  HUDScrollPanel,
} from "library";
import { getRoute, routeParam } from "utilities/route";
import type { IBetPickPickems } from "types/PickemsTypes";
import type { PickemsEntry } from "hooks/firestore/pickems/types";
import { PickemsTransforms } from "selectors";
import { setModal } from "utilities/UI/uiSlice";
import {
  useDispatch,
  useHasPermission,
  useIsProfileComplete,
  usePermission,
  useSelector,
} from "hooks";
import { useEntry } from "hooks/firestore/betting/useEntries";
import { useIsLoggedIn } from "hooks";
import {
  usePickems,
  usePickemsContest,
  usePickemsMarkets,
} from "hooks/firestore";
import { getUnauthorisedMessageElement } from "./PickSlip";
import {
  selectContestPicks,
  selectNewContestPicks,
  selectPointsPotential,
} from "./pickslipSlice";
import { UnauthorizedPickemsAlerts } from "sections/Betting/Betslip/components/UnauthorizedAlerts";
import * as styles from "./PickSlip.module.scss";
import { Button } from "components/Button";
import { ReactComponent as TrashIcon } from "sections/Betting/Betslip/assets/trash.svg";
import { ReactComponent as ChevronDown } from "assets/icons/arrow-down-1.svg";
import cx from "classnames";

const findPickProblem = (
  pick: IBetPickPickems,
  actions: ReturnType<typeof usePickems>["actions"],
  marketNotActive: boolean,
  marketPointsChanged: boolean,
  isPointsOnlyChange: boolean,
) => {
  if (!pick) {
    return null;
  }

  if (pick.errorMessage) {
    return {
      type: "warning",
      message: pick.errorMessage,
    };
  }

  if (!pick.scheduledStartTime) {
    return {
      type: "warning",
      message: "The match you picked has not been scheduled to play",
    };
  }

  if (marketNotActive) {
    return {
      type: "error",
      message: "This match has already started",
    };
  }

  if (marketPointsChanged) {
    return {
      type: "error",
      icon: "refresh",
      message: "The points for this match have updated",
      cta: "Accept",
      ctaCallback: () => {
        actions.acceptPick(pick);
      },
    };
  }

  if (isPointsOnlyChange) {
    return {
      type: "warning",
      message: "You are updating your points for an existing pick",
    };
  }
};

const PickSlipContest = () => {
  const isLoggedIn = useIsLoggedIn();
  const contestId = routeParam(getRoute(), "contest");
  const { props, actions } = usePickems();
  const [contest] = usePickemsContest(contestId);
  const [entry] = useEntry<PickemsEntry>(contestId);
  const [marketsData, marketsLoading] = usePickemsMarkets(contestId);
  const profileComplete = useIsProfileComplete();
  const pointsPotential = useSelector((state) =>
    selectPointsPotential(state, contest, entry),
  );
  const picks = useSelector(selectContestPicks);
  const newPicks = useSelector((state) => selectNewContestPicks(state, entry));
  const permission = usePermission("submitFreeRoster");
  const canSubmitPickems = useHasPermission("submitPickems");

  const dispatch = useDispatch();

  const markets = useMemo(() => {
    if (marketsLoading || !marketsData) return [];

    const marketsCopy = [...marketsData];
    marketsCopy?.forEach((market) => {
      Object.keys(market.outcomes ?? {}).forEach((outcomeId) => {
        const outcome = market.outcomes[outcomeId];
        const picksCopy = picks?.map((p) => ({ ...p })) ?? [];
        const pick = picksCopy.find(
          (pick: IBetPickPickems) => pick.outcomeId === outcome.id,
        );
        if (pick) {
          if (pick.freshOdds !== outcome.odds) {
            // if the points change back to the original points before accepting we need to not show the odds changed message
            pick.oddsChanged = outcome.odds !== pick.lockedOdds;
          }

          pick.freshOdds = outcome.odds;
          actions.updatePickslip({ picks: picksCopy });
        }
        outcome.selected = !!pick;
        outcome.selectedAgainst =
          !pick && !!picksCopy.find((x) => x.marketId === market.id);
      });
    });

    return marketsCopy;
  }, [marketsData]);

  const unauthorisedMessage = getUnauthorisedMessageElement(
    isLoggedIn,
    profileComplete,
    permission,
  );

  const pickCount = picks?.length ?? 0;
  const newPickCount = newPicks?.length ?? 0;

  const bodyChildren: JSX.Element[] = [];
  let contentFooter: JSX.Element | null = null;

  const existingSelections = entry?.picks ?? [];
  const filteredExistingSelections = existingSelections?.filter((selection) => {
    const market = marketsData.find(
      (market) => market.event?.id === selection.eventId,
    );

    if (market?.status !== "ACTIVE") return false;

    return selection?.result === "UNDECIDED";
  });
  const existingPickCount = filteredExistingSelections?.length || 0;

  const marketsWithOutcomes =
    markets.filter((m) => Object.keys(m.outcomes ?? {})?.length) ?? [];
  const marketCount = marketsWithOutcomes.length;
  const activeMarketsCount = markets.reduce((sum, market) => {
    const hasOutcomes = !!Object.keys(market.outcomes ?? {}).length;
    if (
      PickemsTransforms.isPickAvailable(market, market.event) &&
      hasOutcomes
    ) {
      return sum + 1;
    } else {
      return sum;
    }
  }, 0);

  const remainingCount = Math.max(
    activeMarketsCount - newPickCount - existingPickCount,
    0,
  );

  let canMakePick =
    canSubmitPickems &&
    pickCount > 0 &&
    remainingCount < 1 &&
    !unauthorisedMessage;

  const notificationChildren: JSX.Element[] = [];

  if (props.pickAcceptedId) {
    notificationChildren.push(
      <HUDPanelNotification
        key="pick-accepted"
        icon="tickSmall"
        type="info"
        text={
          <>
            <div>Picks Accepted.</div>{" "}
            <div>
              Entry Id: P-{contest?.contestNumber ?? null}-
              {props.pickAcceptedId}
            </div>
          </>
        }
        action={() => actions.updatePickslip({ pickAcceptedId: null })}
      />,
    );
  }

  if (props.error) {
    notificationChildren.push(
      <HUDPanelNotification
        key="error"
        icon="error"
        type="error"
        text={props.error}
        action={() => actions.updatePickslip({ error: null })}
      />,
    );
  }

  if (unauthorisedMessage) {
    notificationChildren.push(unauthorisedMessage);
  }
  if (
    (pickCount > 0 && remainingCount > 0) ||
    (existingPickCount > 0 && remainingCount > 0)
  ) {
    notificationChildren.push(
      <HUDPanelNotification
        key="picks-remaining"
        icon="warning"
        type="warning"
        text={
          remainingCount +
          (remainingCount === 1 ? " pick" : " picks") +
          " remaining."
        }
      />,
    );
  }

  if (pickCount > 0 || existingPickCount > 0) {
    bodyChildren.push(
      ...picks.map((pick: IBetPickPickems) => {
        const selection = entry?.picks?.find((s) => s.eventId === pick.eventId);
        const market = contest?.markets?.find((x) => x.id === pick.marketId);
        const entryPoints = selection ? selection.odds : pick.odds;
        const isAdjustment = pick.odds !== entryPoints;
        const isPointsOnlyChange =
          isAdjustment && selection?.outcomeId === pick.outcomeId;
        const marketNotActive = market?.status !== "ACTIVE";

        const problem = findPickProblem(
          pick,
          actions,
          marketNotActive,
          pick.oddsChanged,
          isPointsOnlyChange,
        );

        if (problem?.type === "error") {
          canMakePick = false;
        }

        return (
          <div
            key={pick.sourceId + ":" + pick.outcomeId}
            className={styles.pick}
          >
            <BetCard
              message={
                !canSubmitPickems ? (
                  <BetMessage
                    icon={"warning"}
                    type={"warning"}
                    message={"You are not permitted to perform this action"}
                  />
                ) : problem ? (
                  <BetMessage
                    type={(problem.type as any) || "error"}
                    icon={problem.icon}
                    message={problem.message}
                    cta={problem.cta || "Remove"}
                    ctaCallback={
                      problem.ctaCallback ||
                      (() => {
                        actions.removePick(pick);
                      })
                    }
                  />
                ) : null
              }
            >
              <BetPick
                selection={{
                  ...pick,
                  marketName: pick.odds ? "POINTS" : "",
                }}
                active={true}
                oddsFormat="points"
                doRemoveSelection={() => {
                  actions.removePick(pick);
                }}
                oddsIncreased={pick.odds > entryPoints}
                oddsDecreased={pick.odds < entryPoints}
                canClose
              />
            </BetCard>
          </div>
        );
      }),
    );
  }

  const renderTrashButton = () => (
    <Button
      variant={`blurred`}
      className={cx(styles.iconButton, styles.trash)}
      onClick={() => {
        actions.clearPicks();
      }}
    >
      <TrashIcon />
    </Button>
  );

  contentFooter =
    pickCount > 0 ? (
      <>
        <div className={styles.footer}>
          <div className={styles.stats}>
            <div>
              <div>{existingPickCount > 0 ? "Changes" : "Picks"}</div>
              <div>
                {pickCount}/{marketCount}
              </div>
            </div>

            {existingPickCount > 0 ? (
              <div>
                <div>Picks</div>
                <div>
                  {existingPickCount}/{marketCount}
                </div>
              </div>
            ) : null}
            <div>
              <div>Points</div>
              <div>{pointsPotential} PTS</div>
            </div>
          </div>
        </div>

        <div className={styles.controls}>
          {isLoggedIn ? (
            canMakePick &&
            props.confirmContestId &&
            props.confirmContestId === contest.id ? (
              <>
                <Button
                  variant={`blurred`}
                  className={cx(styles.iconButton, styles.back)}
                  onClick={() =>
                    actions.updatePickslip({ confirmContestId: null })
                  }
                >
                  <ChevronDown />
                </Button>
                <Button
                  variant={`warning`}
                  className={styles.button}
                  size={`md`}
                  onClick={() => actions.submitPicks({ contest, entry })}
                  disabled={props.isLoading}
                  loading={props.isLoading}
                >
                  {existingPickCount > 0 ? "Confirm Changes" : "Confirm Picks"}
                </Button>
              </>
            ) : (
              <>
                {renderTrashButton()}
                <Button
                  size={`md`}
                  className={styles.button}
                  disabled={!canMakePick || props.isLoading}
                  loading={props.isLoading}
                  onClick={() => actions.submitPicks({ contest, entry })}
                >
                  {existingPickCount > 0 ? "Make Changes" : "Submit Picks"}
                </Button>
              </>
            )
          ) : (
            <>
              {renderTrashButton()}
              <Button
                size={`md`}
                className={styles.button}
                onClick={() => {
                  dispatch(setModal("signIn"));
                }}
              >
                Log In To Pick
              </Button>
            </>
          )}
        </div>
      </>
    ) : null;

  const step = canMakePick ? (remainingCount === 0 ? 3 : 2) : 1;

  const contentBody = (
    <div>
      <UnauthorizedPickemsAlerts />

      {bodyChildren}
      {pickCount > 0 && <br />}
      {canSubmitPickems && pickCount < 1 && existingPickCount < 1 && (
        <HUDPanelEmpty
          icon="pick"
          message={
            existingPickCount > 0 === false
              ? "You haven't yet made a Pick'ems pick."
              : remainingCount > 0
                ? "You've locked in " +
                  existingPickCount +
                  " picks but still have " +
                  remainingCount +
                  " remaining."
                : existingPickCount +
                  " out of " +
                  marketCount +
                  " picks are locked in.\n"
          }
        />
      )}
      {canSubmitPickems && (
        <>
          <HUDInstructions
            instructions={
              !contest
                ? [
                    {
                      text: "Select a Pick'ems contest to enter",
                      selected: true,
                    },
                  ]
                : existingPickCount < 1
                  ? [
                      {
                        hidden: pickCount > 0,
                        text: "Select your winners on the left.",
                        selected: step === 1,
                      },
                      {
                        text:
                          "Lock " +
                          (remainingCount > 0
                            ? remainingCount + " more"
                            : "your") +
                          " picks in.",
                        selected: step === 2,
                      },
                      { text: "Click SUBMIT PICKS.", selected: step === 3 },
                    ]
                  : [
                      {
                        hidden: pickCount > 0,
                        text: "You can make adjustments to any match that has not yet started.",
                        selected: step === 3,
                      },
                      {
                        text: "Click MAKE CHANGES to repick.",
                        selected: step === 3,
                      },
                    ]
            }
          />
          {pickCount < 1 && (
            <p className={styles.newRounds}>
              The players with the most points win. <br />
              New contests appear{" "}
              {PickemsTransforms.getContestFrequencyDescription()}.
            </p>
          )}
        </>
      )}
    </div>
  );

  return (
    <HUDScrollPanel
      title="PickSlip"
      contentNotifications={notificationChildren}
      tabs={[
        {
          title: "Pick'ems",
          isActive: true,
          count: pickCount.toString(),
          badgeType: "default",
        },
      ]}
      className={styles.container}
      count={pickCount}
      contentBody={<div className={styles.pickemsBody}>{contentBody}</div>}
      contentFooter={contentFooter}
    />
  );
};

export default PickSlipContest;
